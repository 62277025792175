import $ from 'jquery';
import _ from 'lodash-contrib';
import SearchController from './search_controller.js';
import Offcanvas from 'bootstrap/js/dist/offcanvas';

export default class extends SearchController {
  static targets = super.targets.concat([
    'sidebar',
    'facilityInput',
    'venueInput',
    'sortInput',
    'rangeInput',
    'rangeLabel',
    'ignoreRangeCheck',
    'locationInput',
    'locationButton',
  ]);

  static values = {
    range: { type: Number, default: 50 },
  };

  initialize() {
    super.initialize();

    Promise.resolve().then(() => {
      this.onVenueChange();
    });
  }

  submit() {
    super.submit();
  }

  onSuccess(e) {
    super.onSuccess(e);

    const [, , xhr] = e.detail;
    const params = this.filterQueryParams(xhr.responseURL);
    this.updateSortState(params);
  }

  updateSortState(params) {
    $(this.sortInputTarget)
      .find('option[data-search-param-dependency]')
      .each((index, elem) => {
        const dependency = $(elem).data('search-param-dependency');
        $(elem).prop('disabled', !params[dependency]);
      });

    if ($(this.sortInputTarget).find(':selected').prop('disabled')) {
      $(this.sortInputTarget)
        .find('option:enabled:first')
        .prop('selected', true);
    }
  }

  onVenueChange() {
    const venueOptions = $(this.venueInputTarget).select2('data');
    const allowSelectingFacilities =
      venueOptions.length === 1 && !venueOptions[0].isTag;
    const allowSelectingRange = !allowSelectingFacilities;
    const select2Options = $(this.facilityInputTarget).data('select2').options
      .options;

    if (allowSelectingFacilities) {
      $(this.facilityInputTarget).select2(
        _.merge(select2Options, {
          ajax: {
            url: $(this.facilityInputTarget)
              .data('search-path')
              .replace(':venue_id', venueOptions[0].id),
          },
          placeholder: 'Select a facility',
        })
      );
    } else {
      $(this.facilityInputTarget).select2(
        _.merge(select2Options, {
          placeholder: $(this.facilityInputTarget).attr('placeholder'),
        })
      );

      $(this.facilityInputTarget).val(null).trigger('change');
    }

    this.facilityInputTarget.disabled = !allowSelectingFacilities;
    this.rangeInputTarget.disabled = !allowSelectingRange;
    this.ignoreRangeCheckTarget.disabled = !allowSelectingRange;
    this.locationButtonTarget.disabled = !allowSelectingRange;
    this.locationInputTarget.disabled = !allowSelectingRange;
  }

  closeSearch() {
    const sidebarOffcanvas = new Offcanvas(this.sidebarTarget);
    sidebarOffcanvas.hide();
  }

  openSearch() {
    const sidebarOffcanvas = new Offcanvas(this.sidebarTarget);
    sidebarOffcanvas.show();
  }

  isParamClearable(value, key) {
    if (key === 'time_slots') {
      return (
        value.end_date ||
        value.end_time ||
        value.start_date ||
        value.start_time ||
        value.repeat_booking == '1'
      );
    }
    if (key === 'venue') {
      return (
        this.venueInputTarget.value !=
        $(this.venueInputTarget).data('search-clear-value')
      );
    }
    return super.isParamClearable(value, key);
  }

  clear() {
    this.rangeInputTarget.disabled = false;
    this.rangeInputTarget.value = this.rangeValue;

    this.rangeLabelTarget.innerHTML = `Search within ${this.rangeValue} miles`;

    this.ignoreRangeCheckTarget.disabled = false;
    this.ignoreRangeCheckTarget.checked = false;

    this.locationInputTarget.disabled = false;
    this.locationButtonTarget.disabled = false;

    super.clear();
  }
}
