import $ from 'jquery';
import Rails from '@rails/ujs';
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'tableFrame',
    'tableContent',
    'availabilityCta',
    'bookNowCta',
  ];
  static values = {
    spinner: String,
  };

  connect() {
    $(this.tableFrameTarget).on('turbo:before-fetch-request', () => {
      if (this.hasTableContentTarget) {
        this.tableContentTarget.innerHTML = this.spinnerValue;
      }
    });
  }

  dispatchBookingUpdated() {
    Rails.fire(window, 'booking:updated');
  }

  onBookingCreated() {
    this.showBookNowCta();
    this.reloadBookingsTable();
  }

  onBookingUpdated() {
    this.reloadBookingsTable();
  }

  reloadBookingsTable() {
    this.tableFrameTarget.reload();
  }

  showBookNowCta() {
    $(this.availabilityCtaTargets).addClass('d-none');
    $(this.bookNowCtaTargets).removeClass('d-none');
  }
}
